import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import ArticlesList from '@pretto/bricks/website/shared/components/ArticlesList'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const NewsPage = ({ title, ...props }) => (
  <div>
    <Wrapper full>
      <S.Title>
        <Heading size="large" type="h1">
          {title}
        </Heading>
      </S.Title>
    </Wrapper>

    <Wrapper>
      <S.Content>
        <ArticlesList {...props} />
      </S.Content>
    </Wrapper>
  </div>
)

NewsPage.propTypes = {
  title: PropTypes.string.isRequired,
}

export default memo(NewsPage)
