import Icon from '@pretto/bricks/components/iconography/Icon'
import Text from '@pretto/bricks/components/typography/Text'
import ArticlePreviewGroup from '@pretto/bricks/website/shared/components/ArticlePreviewGroup'
import Link from '@pretto/bricks/website/utility/Link'

import path from 'path'
import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ArticlesList = ({ articles, baseUrl, currentPageIndex, hasNextPage, hasPreviousPage }) => (
  <div>
    {articles && <ArticlePreviewGroup articles={articles} />}

    <S.Pagination>
      {hasPreviousPage && (
        <S.Link>
          <Link
            href={
              currentPageIndex === 2 ? path.join(baseUrl, '/') : path.join(baseUrl, `/page/${currentPageIndex - 1}/`)
            }
          >
            <S.LinkContent>
              <S.Icon previous>
                <Icon name="arrow-left" />
              </S.Icon>

              <Text>Page précédente</Text>
            </S.LinkContent>
          </Link>
        </S.Link>
      )}

      {hasNextPage && (
        <S.Link next>
          <Link href={path.join(baseUrl, `page/${currentPageIndex + 1}/`)}>
            <S.LinkContent>
              <Text>Page suivante</Text>

              <S.Icon next>
                <Icon name="arrow-right" />
              </S.Icon>
            </S.LinkContent>
          </Link>
        </S.Link>
      )}
    </S.Pagination>
  </div>
)

ArticlesList.propTypes = {
  /** List of articles in the page. */
  articles: PropTypes.array.isRequired,
  /** Url to construct pages url from. */
  baseUrl: PropTypes.string.isRequired,
  /** Current page index. */
  currentPageIndex: PropTypes.number.isRequired,
  /** Whether or not the current page has a next page. */
  hasNextPage: PropTypes.bool.isRequired,
  /** Whether or not the current page has a previous page */
  hasPreviousPage: PropTypes.bool.isRequired,
}

export default memo(ArticlesList)
