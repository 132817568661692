import NewsPage from '@pretto/bricks/website/shared/pages/NewsPage'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { memo } from 'react'
import Helmet from 'react-helmet'
import url from 'url'

import Ebook from '../../components/Ebook'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'

const SEO_DESCRIPTION =
  "Pretto vous propose des articles d'actualités sur l'achat immobilier, le crédit et l'emménagement."
const SEO_IMAGE = 'website/highlight/Thumbnail_Actualités'

const Actualites = props => {
  const {
    allWpPostNews: { nodes: articles },
    site: {
      siteMetadata: { siteUrl },
    },
    wpCategory: { name, uri },
  } = props.data

  const { currentPage, maxPages } = props.pageContext

  const hasPreviousPage = currentPage > 1
  const hasNextPage = currentPage < maxPages

  const links = []

  if (hasPreviousPage) {
    links.push({
      href: url.resolve(siteUrl, currentPage === 2 ? '/actualites/' : `/actualites/page/${currentPage - 1}/`),
      rel: 'prev',
    })
  }

  if (hasNextPage) {
    links.push({
      href: url.resolve(siteUrl, `/actualites/page/${currentPage + 1}/`),
      rel: 'next',
    })
  }

  const breadcrumbItems = [{ title: name, url: uri }]

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      <NewsPage
        articles={articles}
        baseUrl="/actualites/"
        currentPageIndex={currentPage}
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
        title="Actualités"
      />

      <Ebook />

      <Helmet link={links} />

      <Schema
        breadcrumbItems={breadcrumbItems}
        data={{
          description: SEO_DESCRIPTION,
          image: SEO_IMAGE,
          title: 'Actualités',
        }}
      />

      <SEO
        canonical="/actualites/"
        category="Actualités"
        description={SEO_DESCRIPTION}
        image={SEO_IMAGE}
        title="Actualités - Pretto"
      />
    </Layout>
  )
}

Actualites.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    maxPages: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(Actualites)

export const query = graphql`
  query Actualites($categoryId: Int!, $limit: Int!, $skip: Int!) {
    allWpPostNews: allWpPost(
      filter: { categories: { nodes: { elemMatch: { databaseId: { eq: $categoryId } } } } }
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        excerpt
        id
        postTypeMeta {
          highlightedImage: highlightedimage
        }
        title
        uri
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    wpCategory(slug: { eq: "actualites" }) {
      name
      uri
    }
  }
`
