import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export const Content = styled.div`
  margin-bottom: ${g(8)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(12)};
  }
`

export const Title = styled.div`
  padding: ${g(3)} 0px;
  margin-bottom: ${g(3)};
  text-align: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(7)} 0px;
    margin-bottom: ${g(8)};
  }
`
