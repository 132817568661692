import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import DateSignature from '@pretto/bricks/website/shared/components/DateSignature'
import Link from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'
import { memo, useRef } from 'react'

import * as S from './styles.js'

const ArticlePreview = ({ date, postTypeMeta: { highlightedImage }, excerpt, uri, modified, title }) => {
  const titleRef = useRef()

  const handleImageClick = () => {
    titleRef.current.click()
  }

  return (
    <article>
      <S.Nav>
        {highlightedImage && <S.Image alt={title} path={highlightedImage} onClick={handleImageClick} />}

        <S.Title>
          <Link href={uri} ref={titleRef}>
            <SubHeading>{title}</SubHeading>
          </Link>
        </S.Title>

        {date && modified && (
          <S.Date>
            <DateSignature publishDate={date} updateDate={modified} />
          </S.Date>
        )}
      </S.Nav>

      {excerpt && (
        <S.Excerpt>
          <Text type="p">{excerpt}</Text>
        </S.Excerpt>
      )}
    </article>
  )
}

ArticlePreview.propTypes = {
  /** Slug of the post image. */
  postTypeMeta: PropTypes.shape({ highlightedImage: PropTypes.string.isRequired }).isRequired,
  /** Published date. */
  date: PropTypes.string,
  /** Integer representing the chapter number. Appears in the body section. */
  excerpt: PropTypes.string,
  /** Published date. */
  modified: PropTypes.string,
  /** Url of the given article. */
  uri: PropTypes.string.isRequired,
  /** Appears on top of the body section. */
  title: PropTypes.string.isRequired,
}

export default memo(ArticlePreview)
