import Text from '@pretto/bricks/components/typography/Text'
import temporal from '@pretto/bricks/core/utility/temporal'

import PropTypes from 'prop-types'

const DateSignature = ({ publishDate, updateDate }) => (
  <Text variant="neutral-1-60">
    {publishDate === updateDate ? 'Publié' : 'Mis à jour'} le {temporal(updateDate).format('LL')}
  </Text>
)

DateSignature.propTypes = {
  /** Date of publish. */
  publishDate: PropTypes.string.isRequired,
  /** Date of update. */
  updateDate: PropTypes.string.isRequired,
}

export default DateSignature
