import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'
import styled from 'styled-components'

export const Date = styled.div`
  margin-top: ${g(1)};
`

export const Excerpt = styled.div`
  color: ${({ theme }) => theme.legacy.colors.neutral1.fade(60)};
  display: none;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: block;
    margin-top: ${g(2)};
  }
`

export const Image = styled(BaseImage).attrs({ options: { aspectRatio: '16:9', crop: 'fill', width: ng(45) } })`
  border-radius: ${g(1 / 2)};
  display: block;
  width: 100%;
  cursor: pointer;
`

export const Title = styled.div`
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(2)};
  }
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }

  > * {
    flex: 1;

    &:first-child {
      margin-right: ${g(1.5)};

      @media only screen and (min-width: ${breakpoints.tablet}) {
        margin-right: 0;
      }
    }
    &:last-child {
      margin-left: ${g(1.5)};

      @media only screen and (min-width: ${breakpoints.tablet}) {
        margin-left: 0;
      }
    }
  }
`
