import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Pagination = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.legacy.colors.primary1.default};
  display: flex;
  justify-content: space-between;
  margin-top: ${g(5)};
`

export const Link = styled.div`
  ${({ next }) =>
    next &&
    css`
      margin-left: auto;
      text-align: right;
    `}
`

export const LinkContent = styled.div`
  display: flex;
  align-items: center;
`

export const Icon = styled.div`
  ${({ previous }) =>
    previous
      ? css`
          margin-right: ${g(2)};
        `
      : css`
          margin-left: ${g(2)};
        `}
`
